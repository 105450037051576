$first-color: #282c34;
$second-color: #303030;
$third-color: #58b09c;
$fourth-color: #f34213;

$white: #fff;
$black: #000;

$transparent: #ffffff7f;

$main-column-width: 1200px;

h1,
h2 {
	color: $third-color;
}

h3,
h4,
h5,
h6 {
	color: $second-color;
}

p {
	color: $black;
}

body {
	margin: 0;
	font-family: 'europa', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family:
		source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

$phone: 480px;
$tablet: 768px;
$desktop: 1024px;
$widescreen: 1280px;

@keyframes moveInBottom {
	0% {
		opacity: 0;
		transform: translateY(3rem);
	}

	100% {
		opacity: 1;
		transform: translate(0);
	}
}
